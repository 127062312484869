const skillData = {
  languages: [
    { name: "C#", level: 85 },
    { name: "Python", level: 85 },
    { name: "SQL", level: 85 },
    { name: "JavaScript", level: 75 },
    { name: "C", level: 65 },
    { name: "C++", level: 65 },
    { name: "Java", level: 65 },
    { name: "Rust", level: 40 },
  ],
  systems: ["Windows", "Linux/Unix"],
  topics: [
    "RESTful APIs",
    "CompTIA Security+",
    "Database Engineering",
    "Network Programming",
    "Version Control",
  ],
};

export default skillData;
