// TODO: add more theres

import BlueLakes from "./blue-lakes.jpg";
import AngelsLanding from "./angels-landing.jpg";
import LaFortuna from "./lafortuna.jpg";
import MedRelay from "./med_relay.jpg"

const theres = [
  {
    title: "Lower Blue Lake near Ridgway, CO",
    date: "July 28, 2021",
    img: BlueLakes,
  },
  {
    title: "Very top of Angels Landing, Zion Park",
    date: "August 1, 2021",
    img: AngelsLanding,
  },
  {
    title: "La Fortuna Waterfall, Fortuna, Costa Rica",
    date: "March 12, 2024",
    img: LaFortuna,
  },
  {
    title: "Very top of the Podium, Ithaca, New York",
    date: "February 19, 2025",
    img: MedRelay
  }
];

export default theres;
